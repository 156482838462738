<template>
  <div
    class="rounded px-4 py-1 text-center font-medium"
    :class="{
      'bg-wtf-emerald text-white': variant === 'success',
      'bg-wtf-paradise text-white': variant === 'danger',
    }"
  >
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    variant: {
      type: String,
      default: 'success',
    },
  },
}
</script>
