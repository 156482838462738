<template>
  <AvatarComponent
    :text="store.name"
    :bg-color="store.brand_color"
    :url="store.avatar_url"
    :show-fallback="!store.has_custom_avatar"
  />
</template>

<script>
import AvatarComponent from '@/Components/AvatarComponent.vue'

export default {
  components: {
    AvatarComponent,
  },
  props: {
    store: {
      type: Object,
      required: true,
    },
  },
}
</script>
