<template>
  <div class="relative">
    <div
      :class="reversedLayout ? 'right-0' : 'left-0'"
      class="pointer-events-none absolute top-0 z-20 px-2 py-1 text-grey dark:text-dark-body"
    >
      <slot name="prefix">
        {{ currencySymbol(currency ?? currentStore.currency) }}
      </slot>
    </div>
    <input
      v-bind="$attrs"
      ref="inputRef"
      type="text"
      :class="reversedLayout ? 'text-left' : 'text-right'"
      class="form-input"
    />
  </div>
</template>

<script>
import { watch } from 'vue'
import { useCurrencyInput } from 'vue-currency-input'

export default {
  inheritAttrs: false,

  props: {
    modelValue: {
      type: Number,
      required: true,
    },
    reversedLayout: {
      type: Boolean,
      default: false,
    },
    precision: {
      type: Number,
      default: 2,
    },
    currency: {
      type: String,
      required: false,
    },
  },

  setup(props) {
    const baseOptions = {
      currency: 'USD',
      currencyDisplay: 'hidden',
      valueScaling: 'precision',
      useGrouping: true,
      accountingSign: false,
    }

    const maxValue = 99999

    const { inputRef, setValue, setOptions } = useCurrencyInput({
      ...baseOptions,
      valueRange: {
        min: 0,
        max: maxValue * Math.pow(10, props.precision - 2),
      },
      precision: {
        min: 0,
        max: props.precision,
      },
    })

    watch(
      () => props.precision,
      (value) => {
        setOptions({
          ...baseOptions,
          valueRange: {
            min: 0,
            max: maxValue * Math.pow(10, value - 2),
          },
          precision: {
            min: 0,
            max: value,
          },
        })
      },
    )

    watch(
      () => props.modelValue,
      (value) => {
        setValue(value)
      },
    )

    return { inputRef }
  },
}
</script>
