<template>
  <button
    v-bind="$attrs"
    type="button"
    :class="[
      variant === 'plain' ? 'btn-plain' : 'btn',
      variantClass,
      size === 'small' ? 'btn-small' : '',
      size === 'large' ? 'btn-large' : '',
      round ? 'rounded-full' : '',
      hasSlot('icon') ? 'btn-has-icon-left pl-1 pr-2 font-normal' : '',
      hasSlot('icon-right') ? 'btn-has-icon-right' : '',
      !hasSlot() ? 'btn-icon-only !pr-1' : '',
      size === 'xs' ? 'btn-xs' : '',
      center ? 'btn-center' : '',
    ]"
    :disabled="disabled"
  >
    <span
      v-if="hasSlot('icon')"
      class="h-3 w-3 flex-shrink-0"
      :class="{
        'mr-1': hasSlot(),
        'text-wedges-gray-400': variant !== 'primary',
        'text-white': variant === 'primary',
      }"
    >
      <slot name="icon" />
    </span>
    <slot />
    <span
      v-if="hasSlot('icon-right')"
      class="h-3 w-3 flex-shrink-0 text-wedges-gray-400 dark:text-white-500"
      :class="{
        'text-wedges-gray-400': variant !== 'primary',
        'text-white': variant === 'primary',
      }"
    >
      <slot name="icon-right" />
    </span>
  </button>
</template>

<script>
export default {
  props: {
    variant: {
      type: String,
      default: 'primary',
    },
    size: {
      type: String,
      default: 'normal',
    },
    round: {
      type: Boolean,
      default: false,
    },
    center: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    variantClass() {
      if (this.variant === 'plain') {
        return ''
      }

      return `btn-${this.variant}`
    },
  },
}
</script>
