<template>
  <component :is="currentIcon" v-if="currentIcon" />
</template>

<script>
import useIconCache from '@/Util/useIconCache.js'

export default {
  name: 'SvgVue',

  props: {
    icon: {
      type: String,
      required: true,
    },
  },

  computed: {
    currentIcon() {
      const { loadIcon } = useIconCache()

      return loadIcon(this.icon)
    },
  },
}
</script>
