<script setup>
import { computed, inject } from 'vue'

import ButtonComponent from '@/Components/ButtonComponent.vue'
import SvgVue from '@/Components/SvgVue.vue'

import useCart from '../useCart.js'

const emit = defineEmits(['removeDiscount'])

const cart = useCart(inject('cart'))
const cartModel = cart.cart
const checkoutOptions = inject('checkoutOptions')

const activeDiscount = computed(() => {
  return cartModel.value.discount || null
})
</script>

<template>
  <div class="my-3">
    <template v-if="cart.cartItem.value.quantity > 1">
      <div class="flex items-center py-1">
        <span>Unit price</span>
        <span class="ml-auto" dusk="checkout-form-unit-price">
          <template v-if="cart.cartItem.value.price_model.is_tiered">from</template>
          {{ formatCurrency(cart.cartItem.value.unit_price, cartModel.currency) }}
        </span>
      </div>
      <div class="flex items-center py-1">
        <span>Quantity</span>
        <span class="ml-auto" dusk="checkout-form-quantity">
          {{ formatNumber(cart.cartItem.value.quantity) }}
        </span>
      </div>
      <div v-if="cart.cartItem.value.fixed_fee" class="flex items-center py-1">
        <span>Fixed fee</span>
        <span class="ml-auto" dusk="checkout-form-fixed-fee">
          {{ formatCurrency(cart.cartItem.value.fixed_fee, cartModel.currency) }}
        </span>
      </div>
    </template>
    <div v-if="cart.hasSetupFee()" class="flex items-center py-1">
      <span>Setup fee</span>
      <span class="ml-auto" dusk="checkout-form-setup-price">
        {{ formatCurrency(cartModel.setup_fee, cartModel.currency) }}
      </span>
    </div>
    <div class="flex items-center py-1">
      <span>Subtotal</span>
      <span class="ml-auto" dusk="checkout-form-subtotal-price">
        {{ formatCurrency(cartModel.subtotal, cartModel.currency) }}
      </span>
    </div>
    <div v-if="activeDiscount" class="flex items-center py-0.5">
      <span class="flex items-center overflow-hidden">
        Discount
        <span
          class="ml-1 inline-block rounded bg-wedges-gray-50 px-[10px] py-[2px] font-mono text-12 uppercase tracking-widest text-wedges-gray-900"
        >
          <span class="block max-w-30 truncate">
            {{ activeDiscount.code }}
          </span>
        </span>
        <ButtonComponent
          v-if="checkoutOptions.discount"
          variant="plain"
          dusk="checkout-form-remove-discount"
          @click="emit('removeDiscount')"
        >
          <SvgVue icon="Cross Small" class="h-3 w-3 stroke-current text-wedges-gray-400" />
        </ButtonComponent>
      </span>
      <span class="ml-auto"> -{{ formatCurrency(cartModel.discount_total, cartModel.currency) }} </span>
    </div>
    <div v-if="activeDiscount && $parent.form.errors.discount_code" class="flex items-center py-0.5">
      <p class="text-wtf-paradise">
        {{ $parent.form.errors.discount_code }}
      </p>
    </div>
    <div v-if="cartModel.tax_name && cartModel.tax > 0" class="flex items-center py-1" dusk="checkout-form-tax-row">
      <span>
        {{ cartModel.tax_name }}
        ({{ cartModel.tax_rate }}%{{ cartModel.tax_inclusive ? ' included in total' : '' }})
      </span>
      <span class="ml-auto" dusk="checkout-form-tax-price">
        {{ formatCurrency(cartModel.tax, cartModel.currency) }}
      </span>
    </div>
    <div class="flex items-center py-1">
      <span>
        Every
        {{ plural(cart.priceModel.value.renewal_interval_unit, cart.priceModel.value.renewal_interval_quantity, true) }}
      </span>
      <span class="ml-auto">
        {{ formatCurrency(cartModel.recurring_total, cartModel.currency) }}
      </span>
    </div>
    <div v-if="!cart.hasSetupFee()" class="flex items-center py-0.75 text-16 font-medium">
      <span>
        {{ cart.priceModel.value.trial_interval_quantity }}
        {{ cart.priceModel.value.trial_interval_unit }}
        trial
      </span>
      <span class="ml-auto"> FREE </span>
    </div>
    <div v-else class="flex items-center py-0.75 text-16 font-medium">
      <span>Total</span>
      <span class="ml-auto" dusk="checkout-form-total-price">
        {{ formatCurrency(cartModel.total, cartModel.currency) }}
      </span>
    </div>
  </div>
</template>
