import Confetti from 'canvas-confetti'

export default {
  data() {
    return {
      confetti: null,
      showingConfetti: false,
    }
  },

  methods: {
    showConfetti() {
      if (this.showingConfetti) {
        return
      }
      this.showingConfetti = true

      this.$nextTick(() => {
        if (!this.confetti) {
          this.confetti = Confetti.create(this.$refs.confetti, {
            resize: true,
            useWorker: true,
          })
        }

        this.confetti({
          particleCount: 200,
          startVelocity: 45,
          spread: 180,
          ticks: 300,
          colors: [
            '#FF7D52', //coral
            '#00ACFF', //bluejeans
            '#2DCA72', //emerald
            '#FFC233', //mango
            '#7047EB', //majorelle
            '#F53D6B', //paradise
          ],
        }).then(() => {
          this.showingConfetti = false
        })
      })
    },
  },
}
