import { loadStripe } from '@stripe/stripe-js'
import { ref } from 'vue'

export default function useStripe(stripeKey) {
  let stripeHandler
  const elements = ref(null)
  const paymentElement = ref(null)
  const paymentType = ref('card')
  const isComplete = ref(false)
  const error = ref({})
  const processing = ref(false)
  const elementsReady = ref(false)

  function hasError() {
    return Object.keys(error.value).length > 0
  }

  function clearError() {
    error.value = {}
  }

  async function createPaymentElement(
    paymentElementEl,
    mode,
    amount,
    currency,
    email = null,
    applePayEnabled = true,
    googlePayEnabled = true,
  ) {
    if (!stripeHandler) {
      stripeHandler = await loadStripe(stripeKey)
    }

    elements.value = stripeHandler.elements({
      mode: mode,
      amount: amount,
      currency: currency.toLowerCase(),
      locale: 'en',
      appearance: {
        theme: 'stripe',
        variables: {
          colorPrimary: '#7047EB',
          colorBackground: '#ffffff',
          colorText: '#25252D',
          colorDanger: '#F53D6B',
          fontFamily:
            'Inter, ui-sans-serif, system-ui, -apple-system, "system-ui", "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
          fontSizeBase: '14px',
          fontLineHeight: '24px',
          borderRadius: '8px',
          spacingUnit: '4px',
          spacingGridColumn: '16px',
          spacingGridRow: '16px',
          spacingTab: '8px',
        },
        rules: {
          '.Tab:focus': {
            boxShadow: '0',
          },
          '.Tab--selected:focus': {
            boxShadow: '0',
          },
          '.Label': {
            fontSize: '14px',
            lineHeight: '24px',
            fontWeight: '500',
            marginBottom: '12px',
          },
          '.Input': {
            paddingLeft: '16px',
            paddingRight: '16px',
            border: '0',
            boxShadow: '0px 0px 0px 1px rgba(10, 10, 46, 0.16) inset, 0px 1px 1px rgba(10, 10, 11, 0.06)',
          },
          '.Input:hover': {
            boxShadow: '0px 0px 0px 1px rgba(10, 10, 46, 0.24) inset, 0px 1px 1px rgba(10, 10, 11, 0.06)',
          },
          '.Input:focus': {
            boxShadow: '0px 0px 0px 2px #7047EB',
          },
          '.Input:hover:focus': {
            boxShadow: '0px 0px 0px 2px #7047EB',
          },
          '.Input::placeholder': {
            color: '#9ba3af',
          },
          '.Error': {
            fontSize: '14px',
            lineHeight: '24px',
            marginTop: '16px',
          },
          '.Text--redirect': {
            lineHeight: '16px',
          },
          '.TermsText': {
            color: '#6C6C89',
            fontSize: '11px',
            lineHeight: '20px',
            paddingBottom: '16px',
          },
        },
      },
    })

    paymentElement.value = elements.value.create('payment', {
      defaultValues: {
        billingDetails: {
          email: email,
        },
      },
      fields: {
        billingDetails: {
          name: 'never',
          email: 'never',
          address: {
            country: 'never',
            postalCode: 'never',
          },
        },
      },
      wallets: {
        applePay: applePayEnabled ? 'auto' : 'never',
        googlePay: googlePayEnabled ? 'auto' : 'never',
      },
    })
    paymentElement.value.mount(paymentElementEl)

    paymentElement.value.on('change', (event) => {
      paymentType.value = event.value.type
      isComplete.value = event.complete
    })

    paymentElement.value.on('loaderstart', (event) => {
      elementsReady.value = true
    })
  }

  function setReadOnly(readOnly) {
    if (!elements.value || !elements.value.getElement('payment')) {
      return
    }

    elements.value.getElement('payment').update({
      readOnly: readOnly,
    })
  }

  function confirmPayment(clientSecret, returnUrl, billingDetails = {}) {
    return confirmIntent(stripeHandler.confirmPayment, clientSecret, returnUrl, billingDetails)
  }

  function confirmSetup(clientSecret, returnUrl, billingDetails = {}) {
    return confirmIntent(stripeHandler.confirmSetup, clientSecret, returnUrl, billingDetails)
  }

  function confirmIntent(confirmMethod, clientSecret, returnUrl, billingDetails = {}) {
    clearError()
    processing.value = true

    return confirmMethod({
      elements: elements.value,
      clientSecret,
      confirmParams: {
        return_url: returnUrl,
        payment_method_data: {
          billing_details: billingDetails,
        },
      },
      redirect: 'if_required',
    })
      .then((result) => {
        if (result.error) {
          error.value = result.error
          return Promise.reject(error.value)
        }

        if (result.paymentIntent) {
          return result.paymentIntent
        }
        if (result.setupIntent) {
          return result.setupIntent
        }

        return Promise.reject('No payment or setup intent found')
      })
      .catch((error) => {
        console.error(error)
      })
      .finally(() => {
        processing.value = false
      })
  }

  function isReturnFromStripe() {
    return (
      new URLSearchParams(window.location.search).has('payment_intent_client_secret') ||
      new URLSearchParams(window.location.search).has('setup_intent_client_secret')
    )
  }

  function fetchIntentFromClientSecret() {
    const paymentClientSecret = new URLSearchParams(window.location.search).get('payment_intent_client_secret')
    if (paymentClientSecret) {
      return stripeHandler.retrievePaymentIntent(paymentClientSecret).then(({ paymentIntent }) => paymentIntent)
    }
    const setupClientSecret = new URLSearchParams(window.location.search).get('setup_intent_client_secret')
    if (setupClientSecret) {
      return stripeHandler.retrieveSetupIntent(setupClientSecret).then(({ setupIntent }) => setupIntent)
    }
    return Promise.reject(new Error('No client secret found'))
  }

  return {
    elements,
    paymentElement,
    paymentType,
    isComplete,
    error,
    processing,
    elementsReady,
    hasError,
    clearError,
    createPaymentElement,
    setReadOnly,
    confirmPayment,
    confirmSetup,
    isReturnFromStripe,
    fetchIntentFromClientSecret,
  }
}
