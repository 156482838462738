<template>
  <svg width="24" height="40" viewBox="0 0 24 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      id="eabj2pg3wcqu2"
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6.94385 23.1854L14.472 26.658C15.4051 27.0887 16.0637 27.8113 16.4194 28.6403C17.319 30.7396 16.0894 32.8866 14.1593 33.6588C12.2288 34.4306 10.1713 33.9339 9.23584 31.7508L5.95959 24.0863C5.70571 23.4922 6.34348 22.9084 6.94385 23.1854Z"
      fill="#FFC233"
    />
    <path
      id="eabj2pg3wcqu3"
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.39572 20.9376L15.1667 18.0066C17.7494 17.0325 20.5706 18.8756 20.5326 21.5536C20.532 21.5886 20.5314 21.6235 20.5304 21.6588C20.4746 24.2666 17.7319 26.0194 15.206 25.0968L7.40308 22.2473C6.78064 22.0201 6.77604 21.1713 7.39572 20.9376Z"
      fill="#FFC233"
    />
    <path
      id="eabj2pg3wcqu4"
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6.96192 19.9223L14.6011 16.6836C17.1396 15.6073 17.7838 12.3769 15.7957 10.5104C15.7696 10.4858 15.7436 10.4616 15.7172 10.4373C13.768 8.63208 10.5457 9.26767 9.43605 11.6454L6.00803 18.9914C5.73452 19.5773 6.35267 20.1806 6.96192 19.9223Z"
      fill="#FFC233"
    />
    <path
      id="eabj2pg3wcqu5"
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4.99467 18.6425L7.77204 11.0441C8.11638 10.102 8.0526 9.14118 7.69661 8.31219C6.79515 6.21378 4.35383 5.53642 2.42396 6.30974C0.49439 7.08335 -0.595954 8.84027 0.341386 11.0225L3.6391 18.6787C3.89482 19.2719 4.77329 19.2485 4.99467 18.6425Z"
      fill="#FFC233"
    />
  </svg>
</template>

<style>
#eabj2pg3wcqu2 {
  animation: eabj2pg3wcqu2_c_o 2000ms linear infinite normal forwards;
}
@keyframes eabj2pg3wcqu2_c_o {
  0% {
    opacity: 1;
  }
  60% {
    opacity: 1;
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  }
  80% {
    opacity: 0;
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  }
  100% {
    opacity: 1;
  }
}
#eabj2pg3wcqu3 {
  animation: eabj2pg3wcqu3_c_o 2000ms linear infinite normal forwards;
}
@keyframes eabj2pg3wcqu3_c_o {
  0% {
    opacity: 1;
  }
  40% {
    opacity: 1;
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  }
  60% {
    opacity: 0;
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}
#eabj2pg3wcqu4 {
  animation: eabj2pg3wcqu4_c_o 2000ms linear infinite normal forwards;
}
@keyframes eabj2pg3wcqu4_c_o {
  0% {
    opacity: 1;
  }
  20% {
    opacity: 1;
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  }
  40% {
    opacity: 0;
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  }
  60% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}
#eabj2pg3wcqu5 {
  animation: eabj2pg3wcqu5_c_o 2000ms linear infinite normal forwards;
}
@keyframes eabj2pg3wcqu5_c_o {
  0% {
    opacity: 1;
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  }
  20% {
    opacity: 0;
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  }
  40% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}
</style>
