import type { UseFetchOptions } from '#app'

export const useApiFetch = <T>(url: string | (() => string), options: UseFetchOptions<T> = {}) => {
  const config = useRuntimeConfig()
  const appConfig = useAppConfig()

  let headers: Record<string, any> = {
    accept: 'application/json',
    ...options?.headers,
  }

  if (process.client) {
    const xsrfToken = useCookie('XSRF-TOKEN')

    if (xsrfToken && xsrfToken.value !== null) {
      headers['X-XSRF-TOKEN'] = xsrfToken.value
    }
  }

  if (process.server) {
    headers = {
      ...headers,
      ...useRequestHeaders(['cookie']),
    }
  }

  return useFetch(url, {
    baseURL: config.public.api.baseUrl,
    credentials: 'include',
    timeout: 100000, // 10 seconds
    ...options,
    onRequest: ({ options }) => {
      options.headers = headers
    },
    onResponseError({ request, response, options, error }) {
      // Throw an error to the Vercel console
      if (response.status >= 500) {
        console.error(response._data)
      }

      return error
    },
  })
}
