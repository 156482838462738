<template>
  <div class="bg-white py-1.5 flex flex-col md:flex-row items-center justify-between w-full">
    <div class="py-1 md:pl-4 mb-1 md:mb-0">
      <a href="https://lemonsqueezy.com" target="_blank">
        <Logo class="w-[182px] h-auto" />
      </a>
    </div>
    <div class="flex items-center space-x-2 pl-3 md:pl-0 pr-3">
      <div class="flex items-center pl-1.5 pr-2 py-1 bg-wedges-green-50 text-wedges-green-700 rounded">
        <svg
          class="w-3 h-3 fill-current text-wedges-green-600 hidden md:block"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M8.5 7.75C8.5 5.94398 9.88381 4.5 12 4.5C14.1162 4.5 15.5 5.94398 15.5 7.75V9H8.5V7.75ZM7 9V7.75C7 5.05602 9.11619 3 12 3C14.8838 3 17 5.05602 17 7.75V9C18.105 9 19 9.895 19 11V19C19 20.105 18.105 21 17 21H7C5.895 21 5 20.105 5 19V11C5 9.895 5.895 9 7 9ZM13.0607 14.8107C12.9656 14.9057 12.8611 14.9853 12.75 15.0495V17.09C12.75 17.5042 12.4142 17.84 12 17.84C11.5858 17.84 11.25 17.5042 11.25 17.09V15.0495C11.1389 14.9853 11.0344 14.9057 10.9393 14.8107C10.3536 14.2249 10.3536 13.2751 10.9393 12.6893C11.5251 12.1036 12.4749 12.1036 13.0607 12.6893C13.6464 13.2751 13.6464 14.2249 13.0607 14.8107Z"
            fill="#26A95F"
          />
        </svg>
        <p class="ml-0.75 text-13 text-center md:text-left">
          Secure Checkout by Lemon Squeezy (<button
            class="underline hover:text-wedges-green-900"
            @click="showCheckoutModal = true"
          >
            Learn more</button
          >)
        </p>
      </div>
      <slot name="account-menu" />
    </div>
  </div>
  <CheckoutModal :is-open="showCheckoutModal" :store="props.store" @close="showCheckoutModal = false" />
</template>

<script setup>
import { inject, ref } from 'vue'

import CheckoutModal from '@/Components/LsHeader/CheckoutModal.vue'

import Logo from '../../../img/logo.svg'

const props = defineProps({
  store: {
    type: Object,
    required: true,
  },
})

const showCheckoutModal = ref(false)

const eventHub = inject('eventHub')
eventHub.on('openCheckoutModal', () => {
  showCheckoutModal.value = true
})
</script>
