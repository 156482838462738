import { ref, unref, watchEffect } from 'vue'

export default function useCart(cartObject) {
  const cart = ref(null)
  const cartItem = ref(null)
  const priceModel = ref(null)

  watchEffect(() => {
    cart.value = unref(cartObject)
    cartItem.value = cart.value.items[0]
    priceModel.value = cartItem.value.price_model
  })

  const isSubscription = () => priceModel.value.category === 'subscription'
  const isFreeTrial = () => isSubscription() && cart.value.has_free_trial
  const isUsageBased = () => isSubscription() && priceModel.value.usage_aggregation !== null
  const isLeadMagnetOrOneHundredPercentDiscount = () => cart.value.is_lead_magnet_or_one_hundred_percent_discount
  const emailIsConfirmed = () => isSubscription() && cart.value.user_email
  const hasSetupFee = () => priceModel.value.setup_fee_enabled

  return {
    cart,
    cartItem,
    priceModel,
    isSubscription,
    isFreeTrial,
    isUsageBased,
    isLeadMagnetOrOneHundredPercentDiscount,
    emailIsConfirmed,
    hasSetupFee,
  }
}
