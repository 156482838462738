<template>
  <div class="relative h-full w-full">
    <SvgAvatar v-if="showFallback" :text="text" :bg-color="bgColor" class="absolute inset-0 h-full w-full" />
    <img v-if="url" :src="url" alt="" class="absolute inset-0 block h-full w-full rounded-full" />
  </div>
</template>

<script>
import SvgAvatar from '@/Components/SvgAvatar.vue'

export default {
  components: {
    SvgAvatar,
  },
  props: {
    text: {
      type: String,
      required: true,
    },
    bgColor: {
      type: String,
      default: '#7047EB',
    },
    url: {
      type: String,
      default: '',
    },
    showFallback: {
      type: Boolean,
      default: true,
    },
  },
}
</script>
