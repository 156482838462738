import { defineAsyncComponent, shallowRef } from 'vue'

const cache = shallowRef({})

const svgs = import.meta.glob('../../svg/**/*.svg')

export default function useIconCache() {
  function loadIcon(name) {
    if (!cache.value[name]) {
      const match = svgs[`../../svg/${name}.svg`]

      if (typeof match === 'undefined') {
        return
      }

      cache.value[name] = defineAsyncComponent(() => match())
    }

    return cache.value[name]
  }

  return { loadIcon }
}
