<script setup>
import { inject } from 'vue'

const checkoutOptions = inject('checkoutOptions')
</script>

<template>
  <div
    class="flex items-center justify-center rounded-b bg-gray-50 text-center text-13 text-grey"
    :class="{
      'p-3': checkoutOptions.embed,
      'mt-3 rounded p-2': !checkoutOptions.embed,
    }"
  >
    <div class="flex items-center">
      <svg
        class="mr-1 h-3 w-3 fill-current text-light-84"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M8.5 7.75C8.5 5.94398 9.88381 4.5 12 4.5C14.1162 4.5 15.5 5.94398 15.5 7.75V9H8.5V7.75ZM7 9V7.75C7 5.05602 9.11619 3 12 3C14.8838 3 17 5.05602 17 7.75V9C18.105 9 19 9.895 19 11V19C19 20.105 18.105 21 17 21H7C5.895 21 5 20.105 5 19V11C5 9.895 5.895 9 7 9ZM13.0607 14.8107C12.9656 14.9057 12.8611 14.9853 12.75 15.0495V17.09C12.75 17.5042 12.4142 17.84 12 17.84C11.5858 17.84 11.25 17.5042 11.25 17.09V15.0495C11.1389 14.9853 11.0344 14.9057 10.9393 14.8107C10.3536 14.2249 10.3536 13.2751 10.9393 12.6893C11.5251 12.1036 12.4749 12.1036 13.0607 12.6893C13.6464 13.2751 13.6464 14.2249 13.0607 14.8107Z"
          fill="#D3D3D6"
        />
      </svg>
      Payments are secure and encrypted
    </div>
  </div>
</template>
