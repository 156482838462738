<script setup>
import { usePage } from '@inertiajs/inertia-vue3'
import { computed, inject } from 'vue'

import ButtonToggle from '@/Components/Forms/ButtonToggle.vue'

import useCart from '../useCart.js'

const cart = useCart(inject('cart'))
const checkoutOptions = inject('checkoutOptions')
const paypalSubscriptionsEnabled = inject('paypalSubscriptionsEnabled')

const emit = defineEmits(['update:modelValue'])
const props = defineProps({
  modelValue: String,
  isBusy: {
    type: Boolean,
    default: false,
  },
})

const showToggle = computed(() => {
  if (cart.isLeadMagnetOrOneHundredPercentDiscount() || usePage().props.value?.isTestMode) {
    return false
  }
  if (cart.isSubscription() && cart.isUsageBased()) {
    return false
  }
  if (cart.isSubscription() && !paypalSubscriptionsEnabled.value) {
    return false
  }

  return true
})
</script>

<template>
  <div v-if="cart.isLeadMagnetOrOneHundredPercentDiscount()">
    <template v-if="checkoutOptions.embed">
      <p class="mb-2 text-center">Want this for free? Enter the email address we should send it to below.</p>
    </template>
    <template v-else>
      <h2 class="mb-1 text-24 font-medium">Want this for free?</h2>
      <p class="mb-5">Enter the email address we should send it to below.</p>
    </template>
  </div>
  <ButtonToggle
    v-if="showToggle"
    :model-value="props.modelValue"
    class="mb-4"
    :disabled="props.isBusy"
    :options="[
      { key: 'card', label: 'Pay by Card' },
      { key: 'paypal', label: 'Pay with PayPal' },
    ]"
    @update:model-value="(value) => emit('update:modelValue', value)"
  />
</template>
