<script setup>
import { computed, inject } from 'vue'

import ButtonComponent from '@/Components/ButtonComponent.vue'
import SvgVue from '@/Components/SvgVue.vue'

import useCart from '../useCart.js'

const emit = defineEmits(['removeDiscount'])

const cart = useCart(inject('cart'))
const cartModel = cart.cart
const checkoutOptions = inject('checkoutOptions')

const activeDiscount = computed(() => {
  return cartModel.value.discount || null
})
</script>

<template>
  <div class="my-3">
    <div v-if="cart.hasSetupFee()" class="flex items-center py-1">
      <span>Setup fee</span>
      <span class="ml-auto" dusk="checkout-form-setup-price">
        {{ formatCurrency(cartModel.setup_fee, cartModel.currency) }}
      </span>
    </div>
    <div v-if="activeDiscount" class="flex items-center py-0.5" dusk="checkout-form-discount-row">
      <span class="flex items-center overflow-hidden">
        Discount
        <span
          class="ml-1 inline-block rounded bg-wedges-gray-50 px-[10px] py-[2px] font-mono text-12 uppercase tracking-widest text-wedges-gray-900"
        >
          <span class="block max-w-30 truncate">
            {{ activeDiscount.code }}
          </span>
        </span>
        <ButtonComponent
          v-if="checkoutOptions.discount"
          variant="plain"
          dusk="checkout-form-remove-discount"
          @click="emit('removeDiscount')"
        >
          <SvgVue icon="Cross Small" class="h-3 w-3 stroke-current text-wedges-gray-400" />
        </ButtonComponent>
      </span>
      <span v-if="cart.hasSetupFee()" class="ml-auto">
        -{{ formatCurrency(cartModel.discount_total, cartModel.currency) }}
      </span>
    </div>
    <div
      v-if="cart.hasSetupFee() && cartModel.tax_name && cartModel.tax > 0"
      class="flex items-center py-1"
      dusk="checkout-form-tax-row"
    >
      <span>
        {{ cartModel.tax_name }}
        ({{ cartModel.tax_rate }}%{{ cartModel.tax_inclusive ? ' included in total' : '' }})
      </span>
      <span class="ml-auto" dusk="checkout-form-tax-price">
        {{ formatCurrency(cartModel.tax, cartModel.currency) }}
      </span>
    </div>
    <div v-if="cart.hasSetupFee()" class="flex items-center py-0.75 text-16 font-medium">
      <span>Total</span>
      <span class="ml-auto" dusk="checkout-form-total-price">
        {{ formatCurrency(cartModel.total, cartModel.currency) }}
      </span>
    </div>
  </div>
</template>
