<script setup>
import { inject } from 'vue'

import ButtonComponent from '@/Components/ButtonComponent.vue'

import useCart from '../useCart.js'

const emit = defineEmits(['click'])

const cart = useCart(inject('cart'))
const cartModel = cart.cart

const props = defineProps({
  disabled: {
    type: Boolean,
    default: false,
  },
  isPaying: {
    type: Boolean,
    default: false,
  },
})

const isInitialized = inject('isInitialized')
</script>

<template>
  <ButtonComponent
    variant="plain"
    size="large"
    class="w-full bg-lemon-button text-lemon-button-text"
    :disabled="props.disabled"
    dusk="checkout-form-submit"
    @click="emit('click')"
  >
    <span class="mx-auto flex items-center">
      <template v-if="props.isPaying">
        <svg
          class="mr-1 h-3 w-3 animate-spin stroke-current"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0)">
            <path
              d="M17.25 7C15.9295 5.61385 14.0656 4.75 12 4.75C7.99594 4.75 4.75 7.99594 4.75 12C4.75 16.0041 7.99594 19.25 12 19.25C14.0656 19.25 15.9295 18.3862 17.25 17"
              stroke="#141414"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </g>
          <defs>
            <clipPath id="clip0">
              <rect width="24" height="24" fill="white" />
            </clipPath>
          </defs>
        </svg>
        Processing...
      </template>
      <template v-else>
        <template v-if="cart.isFreeTrial()"> Start trial </template>
        <template v-else-if="cart.isUsageBased() && !cart.hasSetupFee()"> Create subscription </template>
        <template v-else-if="cart.isLeadMagnetOrOneHundredPercentDiscount() || cartModel.total < 1">
          Submit Order
        </template>
        <template v-else-if="isInitialized">
          Pay
          {{ formatCurrency(cartModel.total, cartModel.currency) }}
        </template>
        <template v-else> Pay </template>
      </template>
    </span>
  </ButtonComponent>
</template>
