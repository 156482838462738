import _ from 'lodash'

export function getGoogleAnalyticsItemPayload(cart, productName = '') {
  const cartItem = cart.items[0]

  const payload = {
    item_id: cartItem.product_id,
    variant_id: cartItem.variant_id,
    item_list_id: 'products',
    item_list_name: 'Products',
    price: cartItem.price / 100,
    quantity: cartItem.quantity,
  }

  if (productName !== '') {
    payload.item_name = productName
  }

  if (cart.discount) {
    payload.coupon = cart.discount.code
    payload.discount = cart.discount_total
  }

  return payload
}

export function injectGoogleAnalyticsScript(analyticsId) {
  if (typeof window.gtag === 'undefined') {
    let gaScript = document.createElement('script')
    gaScript.setAttribute('async', true)
    gaScript.setAttribute('src', `https://www.googletagmanager.com/gtag/js?id=${analyticsId}`)
    document.head.appendChild(gaScript)

    window.dataLayer = window.dataLayer || []

    window.gtag = function () {
      window.dataLayer.push(arguments)
    }

    window.gtag('js', new Date())
    window.gtag('config', `${analyticsId}`)
  }
}

export function injectMetaPixelScript(pixelId) {
  if (typeof window.fbq === 'undefined') {
    const script = document.createElement('script')
    script.innerText = `!function(f,b,e,v,n,t,s){if(f.fbq)return;n=f.fbq=function(){n.callMethod?n.callMethod.apply(n,arguments):n.queue.push(arguments)};if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';n.queue=[];t=b.createElement(e);t.async=!0;t.src=v;s=b.getElementsByTagName(e)[0];s.parentNode.insertBefore(t,s)}(window, document,'script','https://connect.facebook.net/en_US/fbevents.js');fbq('init', '${pixelId}');fbq('track', 'PageView');`
    document.head.appendChild(script)

    const noScript = document.createElement('noscript')
    const img = document.createElement('img')
    img.height = '1'
    img.width = '1'
    img.style.display = 'none'
    img.src = `https://www.facebook.com/tr?id=${pixelId}&ev=PageView&noscript=1`
    noScript.appendChild(img)
    document.head.appendChild(noScript)
  }
}

export function trackPaymentInfoEvent(cart, productName = '') {
  if (typeof window.gtag !== 'undefined') {
    const eventPayload = {
      currency: cart.currency,
      value: cart.total / 100,
      items: [getGoogleAnalyticsItemPayload(cart, productName)],
    }

    if (cart.discount) {
      eventPayload.coupon = cart.discount.code
    }

    window.parent.postMessage(
      {
        event: 'GA.AddPaymentInfo',
        data: JSON.parse(JSON.stringify(eventPayload)),
      },
      '*',
    )

    window.gtag('event', 'add_payment_info', eventPayload)
  }

  if (typeof window.fbq !== 'undefined') {
    const cartItems = cart.items

    window.fbq('track', 'AddPaymentInfo', {
      content_ids: _.map(cartItems, 'id'),
      contents: _.map(cartItems, (obj) => _.pick(obj, ['id', 'quantity'])),
      currency: cart.currency,
      value: cart.total / 100,
    })
  }
}

export function trackAddToCartEvents(cart, productName = '') {
  if (typeof window.gtag !== 'undefined') {
    window.gtag('event', 'view_cart', {
      currency: cart.currency,
      value: cart.total / 100,
      items: [getGoogleAnalyticsItemPayload(cart, productName)],
    })
  }

  if (typeof window.fbq !== 'undefined') {
    window.fbq('track', 'AddToCart', {
      content_ids: _.map(cart.items, 'id'),
      content_name: productName,
      content_type: 'product',
      contents: _.map(cart.items, (obj) => _.pick(obj, ['id', 'quantity'])),
      currency: cart.currency,
      value: cart.total / 100,
    })
  }
}

export function trackGoogleAnalyticsDiscountEvent(eventName, cart, productName = '') {
  if (typeof window.gtag === 'undefined') {
    return
  }

  const eventPayload = {
    currency: cart.currency,
    value: cart.total / 100,
    items: [getGoogleAnalyticsItemPayload(cart, productName)],
  }

  if (cart.discount) {
    eventPayload.coupon = cart.discount.code
  }

  window.parent.postMessage(
    {
      event: eventName === 'apply_discount' ? 'GA.ApplyDiscount' : 'GA.RemoveDiscount',
      data: eventPayload,
    },
    '*',
  )

  window.gtag('event', eventName, eventPayload)
}

export function trackMetaPixelDiscountEventAdded(cart) {
  if (typeof window.fbq === 'undefined') {
    return
  }
  if (!cart.discount_total) {
    return
  }
  window.fbq('track', 'ApplyDiscount', {
    content_name: 'Discount',
    currency: cart.currency,
    value: cart.discount_total / 100,
    coupon: cart.discount.code,
  })
}
export function trackMetaPixelDiscountEventRemoved(cart) {
  if (typeof window.fbq === 'undefined') {
    return
  }

  window.fbq('track', 'RemoveDiscount', {
    content_name: 'Discount',
    currency: cart.currency,
    value: cart.discount_total / 100,
    coupon: cart.discount.code,
  })
}

export function trackPurchaseEvents(cart, order, productName = '') {
  const orderId = order.data.id

  if (typeof window.gtag !== 'undefined') {
    const eventPayload = {
      transaction_id: orderId,
      value: cart.total / 100,
      currency: cart.currency,
      tax: cart.tax / 100,
      items: [getGoogleAnalyticsItemPayload(cart, productName)],
    }

    if (cart.discount) {
      eventPayload.coupon = cart.discount.code
    }

    window.parent.postMessage(
      {
        event: 'GA.Purchase',
        data: JSON.parse(JSON.stringify(eventPayload)),
      },
      '*',
    )

    window.gtag('event', 'purchase', eventPayload)
  }

  const firstOrderItem = order.data.attributes.first_order_item

  if (typeof window.fbq !== 'undefined') {
    window.fbq('track', 'Purchase', {
      event_id: orderId,
      content_ids: [firstOrderItem.variant_id],
      content_type: 'variant',
      content_name: `${firstOrderItem.product_name} (${firstOrderItem.variant_name})`,
      contents: [
        {
          id: firstOrderItem.variant_id,
          quantity: firstOrderItem.quantity,
        },
      ],
      currency: cart.currency,
      value: cart.total / 100,
    })
  }
}

export function trackGoogleAnalyticsViewItemEvent(cart, productName = '') {
  window.gtag('event', 'view_item', {
    currency: cart.currency,
    value: cart.total / 100,
    items: [getGoogleAnalyticsItemPayload(cart, productName)],
  })
}
