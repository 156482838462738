/** @ts-expect-error */
import { useCheckoutState } from '@/Pages/Web/Components/Checkout/useCheckoutState.js'

const { setState } = useCheckoutState()

function extractParams(path: string) {
  const params: Record<string, string | string[]> = {}
  const regex = /([^_]+)-([^_]+)/g

  let match

  while ((match = regex.exec(path)) !== null) {
    const key = match[1]
    const value = match[2]

    params[key] = value
  }

  return params
}

// Nuxt-specific checkout requests
export const useCheckout = () => {
  const requestUrl = useRequestURL()

  const cart = useCookie('ls_cart_id', {
    domain: `.${requestUrl.hostname}`,
  })

  const route = useRoute()
  const { data: country } = useAsyncData('country', async () => useRequestHeader('x-vercel-ip-country'))
  const { data: referer } = useAsyncData('referer', async () => useRequestHeader('referer'))

  const query = route.query

  // We are using the same component for both `/buy/:variant` and `/` routes
  // Fallback to `cartId` if no `variantId` is present on the request
  const variant = route.params.variantId as string | undefined

  // Throw an error if neither `cartId` nor `variantId` are set
  function checkBindings() {
    if (!cart.value && !variant) {
      throw createError({
        message: 'No cart found',
        statusCode: 404,
      })
    }
  }

  // Get basic checkout and product details
  // This is only called from the backend
  async function getProduct() {
    checkBindings()

    const queryParams = (useRoute().params.queryParams as string) ?? ''
    const params = extractParams(queryParams)

    const { data, error } = await useApiFetch(`/spa-api/details/${variant}`, {
      query: {
        ...query,
        ...params,
      },
      key: 'once',
    })

    if (error.value) {
      throw error.value
    }

    setState(data.value!, false)
  }

  // Retrieve or create a cart. This is called from the client on `/buy/:variant` visits,
  // and from the server on direct `/checkout` visits
  async function setupCart() {
    checkBindings()

    const { data, error } = await useApiFetch<{ cart: Record<string, any> }>('/spa-api/checkout', {
      query: {
        ...(variant ? { variant } : { cart: cart.value }),
        ...query,
        country: country.value,
        referer: referer.value,
      },
      lazy: true,
      key: 'once',
    })

    if (error.value) {
      throw error.value
    }

    cart.value = data.value?.cart.id

    setState(data.value!)
  }

  return {
    getProduct,
    setupCart,
  }
}
