// Based on the Inertia form helper https://github.com/inertiajs/inertia
import Axios from 'axios'

export default function (data = {}) {
  const defaults = JSON.parse(JSON.stringify(data))
  let recentlySuccessfulTimeoutId = null
  let transform = (data) => data

  return {
    ...defaults,
    errors: {},
    hasErrors: false,
    processing: false,
    progress: null,
    wasSuccessful: false,
    recentlySuccessful: false,
    data() {
      return Object.keys(data).reduce((carry, key) => {
        carry[key] = this[key]
        return carry
      }, {})
    },
    transform(callback) {
      transform = callback

      return this
    },
    reset(...fields) {
      if (fields.length === 0) {
        Object.assign(this, defaults)
      } else {
        Object.assign(
          this,
          Object.keys(defaults)
            .filter((key) => fields.includes(key))
            .reduce((carry, key) => {
              carry[key] = defaults[key]
              return carry
            }, {}),
        )
      }

      return this
    },
    clearErrors(...fields) {
      this.errors = Object.keys(this.errors).reduce(
        (carry, field) => ({
          ...carry,
          ...(fields.length > 0 && !fields.includes(field) ? { [field]: this.errors[field] } : {}),
        }),
        {},
      )

      this.hasErrors = Object.keys(this.errors).length > 0

      return this
    },
    serialize() {
      return {
        errors: this.errors,
        ...this.data(),
      }
    },
    unserialize(data) {
      Object.assign(this, data)
      this.hasErrors = Object.keys(this.errors).length > 0
    },
    submit(method, url, options = {}) {
      const data = transform(this.data())

      this.wasSuccessful = false
      this.recentlySuccessful = false
      clearTimeout(recentlySuccessfulTimeoutId)
      this.processing = true

      let request

      if (['post', 'put', 'patch'].includes(method)) {
        request = Axios[method](url, data, options)
      } else {
        request = Axios[method](url, options)
      }

      return request
        .then((response) => {
          this.clearErrors()
          this.wasSuccessful = true
          this.recentlySuccessful = true
          recentlySuccessfulTimeoutId = setTimeout(() => (this.recentlySuccessful = false), 2000)

          return response
        })
        .catch((error) => {
          if (error.response) {
            const errors = error.response.data.errors
            this.errors = Object.keys(errors).reduce((carry, key) => {
              carry[key] = errors[key][0]
              return carry
            }, {})
            this.hasErrors = true
          }

          throw error
        })
        .finally(() => {
          this.processing = false
          this.progress = null
        })
    },
    get(url, options) {
      return this.submit('get', url, options)
    },
    post(url, options) {
      return this.submit('post', url, options)
    },
    put(url, options) {
      return this.submit('put', url, options)
    },
    patch(url, options) {
      return this.submit('patch', url, options)
    },
    delete(url, options) {
      return this.submit('delete', url, options)
    },
  }
}
