<template>
  <div>
    <div
      :class="{
        [`theme-${store.theme}`]: true,
        'theme-area-checkout': true,
      }"
      :style="checkoutStyles"
      class="h-full"
    >
      <div v-if="checkoutOptions.embed">
        <TestModeNotice v-if="cart.test_mode && !isPreview">
          <div v-if="cart.affiliate_id">Affiliate visit detected</div>
        </TestModeNotice>

        <div v-if="showSuccess && showingConfetti" class="fixed z-110 h-screen w-full">
          <canvas ref="confetti" class="absolute z-130 h-full w-full" />
        </div>
        <LsHeader v-if="checkoutMorExperimentEnabled" :store="store">
          <template v-if="currentUser && !isPreview" #account-menu>
            <AccountMenu :is-checkout="true" />
          </template>
        </LsHeader>

        <CheckoutEmbed
          :store="store"
          :cart="cart"
          :stripe-key="stripeKey"
          :product="product"
          :variants="variants"
          :is-multi-variant="isMultiVariant"
          :checkout-options="checkoutOptions"
          :has-valid-discounts="hasValidDiscounts"
          :checkout-response="checkoutResponse"
          :is-fulfilling="isFulfilling"
          :has-failed="hasFailed"
          :disabled="isBusy"
          :show-success="showSuccess"
          :button-url="buttonUrl"
          :button-text="buttonText"
          :is-preview="isPreview"
          :submit-errors="submitForm.errors"
          :purchase-attempt="purchaseAttempt"
          :has-theme-overrides="themeOptions.overlay.override"
          :csp-nonce="cspNonce"
          @change="updateCartItem"
          @submit="submitCheckout"
        />
      </div>
      <BuyerLayout
        v-else
        :show-test-mode-notice="cart.test_mode"
        :is-preview="isPreview"
        :split-background="!checkoutMorExperimentEnabled"
      >
        <template v-if="cart.affiliate_id" #testMode> Affiliate visit detected</template>
        <template v-if="checkoutMorExperimentEnabled" #header>
          <LsHeader :store="store">
            <template v-if="currentUser && !isPreview" #account-menu>
              <AccountMenu :is-checkout="true" />
            </template>
          </LsHeader>
        </template>
        <template v-if="checkoutMorExperimentEnabled">
          <div class="ls-checkout relative max-w-[568px] md:max-w-[1264px] mx-auto min-h-screen md:flex py-8">
            <div class="w-full md:w-1/2 px-4">
              <CheckoutProduct
                v-for="cartItem in cart.items"
                :key="cartItem.id"
                :store="store"
                :cart="cart"
                :cart-item="cartItem"
                :product="product"
                :variants="variants"
                :is-multi-variant="isMultiVariant"
                :checkout-options="checkoutOptions"
                :disabled="isBusy"
                :is-preview="isPreview"
                @change="updateCartItem"
              />
            </div>
            <div class="w-full md:w-1/2 px-4">
              <div class="bg-white rounded-lg md:rounded-xl p-4 md:p-8">
                <CheckoutForm
                  :store="store"
                  :cart="cart"
                  :stripe-key="stripeKey"
                  :checkout-options="checkoutOptions"
                  :has-valid-discounts="hasValidDiscounts"
                  :disabled="isBusy"
                  :is-preview="isPreview"
                  :submit-errors="submitForm.errors"
                  :csp-nonce="cspNonce"
                  @submit="submitCheckout"
                  @payment-processing="paymentIsProcessing = true"
                />
              </div>
            </div>
            <div
              v-if="showSuccess || isFulfilling || hasFailed || purchaseAttempt !== 'allow' || paymentIsProcessing"
              class="fixed inset-0 z-110 flex h-screen w-full items-center px-2"
            >
              <div class="absolute inset-0 z-120 bg-white opacity-40" />
              <canvas ref="confetti" class="absolute z-130 h-full w-full" />
              <div class="relative z-140 mx-auto w-full max-w-70 rounded bg-white text-center shadow-menu">
                <div class="pt-6 pb-3 px-5">
                  <CheckoutProcessing v-if="purchaseAttempt === 'review' || paymentIsProcessing" />
                  <CheckoutBlocked v-else-if="purchaseAttempt === 'block'" />
                  <CheckoutFailed v-else-if="hasFailed" :cart="cart" :checkout-response="checkoutResponse" />
                  <div v-else-if="isFulfilling || showSuccess" class="pb-2">
                    <div class="mx-auto mb-3 w-6">
                      <svg class="h-6 w-6" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="24" cy="24" r="24" fill="var(--lemon-button)" />
                        <path
                          d="M32.5 17.5L20.8125 30.5L15.5 25.5"
                          stroke="var(--lemon-button-text)"
                          stroke-width="3"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </div>
                    <CheckoutSuccess
                      :is-fulfilling="isFulfilling"
                      :button-url="buttonUrl"
                      :button-text="buttonText"
                      :is-preview="isPreview"
                      :product="product"
                    />
                  </div>
                </div>
                <div
                  v-if="purchaseAttempt !== 'block' && !hasFailed"
                  class="bg-wedges-gray-50 px-5 py-3 rounded-b text-13 text-wedges-gray"
                >
                  <p>
                    Merchant of Record services provided to {{ store.name }} by Lemon Squeezy —
                    <span class="text-wedges-gray-900 font-medium">{{ store.descriptor }}</span> will appear on your
                    statement.
                  </p>
                  <p>
                    Need help with your payment or order?
                    <a
                      href="https://www.lemonsqueezy.com/help"
                      target="_blank"
                      class="underline hover:text-wedges-gray-900"
                      >Visit Lemon Squeezy Support</a
                    >
                  </p>
                  <p v-if="invoiceUrl">
                    Need an invoice for your records?
                    <a :href="invoiceUrl" target="_blank" class="underline hover:text-wedges-gray-900"
                      >Generate an invoice</a
                    >
                  </p>
                </div>
                <div v-else class="pb-3" />
              </div>
            </div>
          </div>
        </template>
        <template v-else>
          <div class="ls-checkout relative min-h-screen md:flex">
            <div class="absolute left-1 top-1 ml-3 mt-3 flex items-center">
              <div v-if="cart.is_marketplace">
                <a href="#" class="font-medium" @click.prevent="goBack()">&larr; Back</a>
              </div>
              <template v-else>
                <div v-if="checkoutOptions.logo" class="mr-1.5 h-3 w-3" dusk="checkout-logo">
                  <StoreAvatar :store="store" :small="true" />
                </div>
                <p class="font-medium text-lemon-logo">
                  <a :href="store.url">{{ store.name }}</a>
                </p>
              </template>
            </div>
            <div v-if="currentUser && !isPreview" class="absolute right-0 top-0 mr-3 mt-3">
              <AccountMenu :is-checkout="true" />
            </div>
            <div class="bg-lemon-background md:w-1/2">
              <div class="mx-auto max-w-[512px] px-4 pb-6 pt-15 md:pb-15">
                <CheckoutProduct
                  v-for="cartItem in cart.items"
                  :key="cartItem.id"
                  :store="store"
                  :cart="cart"
                  :cart-item="cartItem"
                  :product="product"
                  :variants="variants"
                  :is-multi-variant="isMultiVariant"
                  :checkout-options="checkoutOptions"
                  :disabled="isBusy"
                  :is-preview="isPreview"
                  @change="updateCartItem"
                />
              </div>
            </div>
            <div class="bg-white md:w-1/2">
              <div class="mx-auto max-w-70 px-4 pb-10 pt-6 md:pt-15">
                <CheckoutForm
                  :store="store"
                  :cart="cart"
                  :stripe-key="stripeKey"
                  :checkout-options="checkoutOptions"
                  :has-valid-discounts="hasValidDiscounts"
                  :disabled="isBusy"
                  :is-preview="isPreview"
                  :submit-errors="submitForm.errors"
                  :csp-nonce="cspNonce"
                  @submit="submitCheckout"
                  @payment-processing="paymentIsProcessing = true"
                />
              </div>
            </div>
            <div
              v-if="showSuccess || isFulfilling || hasFailed || purchaseAttempt !== 'allow' || paymentIsProcessing"
              class="fixed inset-0 z-110 flex h-screen w-full items-center px-2"
            >
              <div class="absolute inset-0 z-120 bg-white opacity-40" />
              <canvas ref="confetti" class="absolute z-130 h-full w-full" />
              <div class="relative z-140 mx-auto w-full max-w-70 rounded bg-white px-5 py-6 text-center shadow-menu">
                <CheckoutProcessing v-if="purchaseAttempt === 'review' || paymentIsProcessing" />
                <CheckoutBlocked v-else-if="purchaseAttempt === 'block'" />
                <CheckoutFailed v-else-if="hasFailed" :cart="cart" :checkout-response="checkoutResponse" />
                <div v-else-if="isFulfilling || showSuccess" class="pb-2">
                  <div class="mx-auto mb-3 w-6">
                    <svg class="h-6 w-6" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <circle cx="24" cy="24" r="24" fill="var(--lemon-button)" />
                      <path
                        d="M32.5 17.5L20.8125 30.5L15.5 25.5"
                        stroke="var(--lemon-button-text)"
                        stroke-width="3"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </div>
                  <CheckoutSuccess
                    :is-fulfilling="isFulfilling"
                    :button-url="buttonUrl"
                    :button-text="buttonText"
                    :is-preview="isPreview"
                    :product="product"
                  />
                </div>
              </div>
            </div>
          </div>
        </template>
      </BuyerLayout>
      <CheckoutDebug v-if="environment === 'local' && !isPreview && !isDusk" :cart="cart" />
    </div>
  </div>
</template>

<script>
import FingerprintJS from '@fingerprintjs/fingerprintjs'
import { useForm } from '@inertiajs/inertia-vue3'
import { useCookies } from '@vueuse/integrations/useCookies'
import axios from 'axios'
import _ from 'lodash'
import { computed } from 'vue'

import LsHeader from '@/Components/LsHeader/LsHeader.vue'
import StoreAvatar from '@/Components/StoreAvatar.vue'
import BuyerLayout from '@/Layouts/BuyerLayout.vue'
import AccountMenu from '@/Layouts/Components/AccountMenu.vue'
import TestModeNotice from '@/Layouts/Components/TestModeNotice.vue'
import Confetti from '@/Mixins/Confetti.js'
import CheckoutBlocked from '@/Pages/Web/Components/Checkout/CheckoutBlocked.vue'
import CheckoutDebug from '@/Pages/Web/Components/Checkout/CheckoutDebug.vue'
import CheckoutEmbed from '@/Pages/Web/Components/Checkout/CheckoutEmbed.vue'
import CheckoutFailed from '@/Pages/Web/Components/Checkout/CheckoutFailed.vue'
import CheckoutForm from '@/Pages/Web/Components/Checkout/CheckoutForm.vue'
import CheckoutProcessing from '@/Pages/Web/Components/Checkout/CheckoutProcessing.vue'
import CheckoutProduct from '@/Pages/Web/Components/Checkout/CheckoutProduct.vue'
import CheckoutSuccess from '@/Pages/Web/Components/Checkout/CheckoutSuccess.vue'
import useCheckout from '@/Pages/Web/Components/Checkout/useCheckout.js'
import Form from '@/Util/Form.js'
import * as Tracking from '@/Util/Tracking.js'

import useCheckoutStyles from './Components/Checkout/useCheckoutStyles.js'
import useStripe from './Components/Checkout/useStripe.js'

export default {
  components: {
    LsHeader,
    CheckoutFailed,
    AccountMenu,
    BuyerLayout,
    CheckoutBlocked,
    CheckoutEmbed,
    CheckoutForm,
    CheckoutProcessing,
    CheckoutProduct,
    CheckoutSuccess,
    StoreAvatar,
    TestModeNotice,
    CheckoutDebug,
  },
  mixins: [Confetti],

  provide() {
    return {
      cart: computed(() => this.cart),
      checkoutOptions: computed(() => this.checkoutOptions),
      paypalClientId: computed(() => this.paypalClientId),
      paypalScriptIntent: computed(() => this.paypalScriptIntent),
      paypalSubscriptionsEnabled: computed(() => this.paypalSubscriptionsEnabled),
      isApi: this.isApi,
      isPreview: this.isPreview,
      isInitialized: computed(() => this.isInitialized),
      billingAddress: computed(() => this.billingAddress),
      checkoutMorExperimentEnabled: computed(() => this.checkoutMorExperimentEnabled),
    }
  },

  props: {
    store: {
      type: Object,
      required: true,
    },
    cart: {
      type: Object,
      required: true,
    },
    stripeKey: {
      type: String,
      required: true,
    },
    paypalClientId: {
      type: String,
      required: true,
    },
    paypalScriptIntent: {
      type: String,
      required: true,
    },
    paypalSubscriptionsEnabled: {
      type: Boolean,
      default: false,
    },
    product: {
      type: Object,
      required: true,
    },
    variants: {
      type: Array,
      required: true,
    },
    isMultiVariant: {
      type: Boolean,
      default: false,
    },
    checkoutOptions: {
      type: Object,
      required: true,
    },
    hasValidDiscounts: {
      type: Boolean,
      required: true,
    },
    isApi: {
      type: Boolean,
      default: false,
    },
    isPreview: {
      type: Boolean,
      default: false,
    },
    isInitialized: {
      type: Boolean,
      default: true,
    },
    settings: {
      type: Object,
      required: true,
    },
    affiliate: {
      type: Object,
      required: false,
    },
    themeOptions: {
      type: Object,
      required: true,
    },
    billingAddress: {
      type: Object,
      default: () => {},
    },
    checkoutMorExperimentEnabled: {
      type: Boolean,
      default: false,
    },
    cspNonce: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      checkoutHandler: null,
      form: useForm(),
      submitForm: Form(),
      checkoutResponse: null,
      isSubmitting: false,
      isFulfilling: false,
      hasFailed: false,
      showSuccess: false,
      hasShownConfetti: false,
      buttonUrl: null,
      buttonText: '',
      invoiceUrl: null,
      paymentIsProcessing: false,
    }
  },

  computed: {
    isBusy() {
      return this.form.processing || this.submitForm.processing || this.checkoutHandler.working
    },
    purchaseAttempt() {
      if (Object.prototype.hasOwnProperty.call(this.submitForm.errors, 'purchase_attempt')) {
        return this.submitForm.errors.purchase_attempt
      }

      return 'allow'
    },
    checkoutStyles() {
      return useCheckoutStyles(this.checkoutOptions, this.themeOptions).value
    },
  },

  watch: {
    isInitialized() {
      this.setCheckoutHandler()

      this.trackVisit()
    },
  },

  created() {
    this.setCheckoutHandler()
  },

  mounted() {
    const stripe = useStripe(this.stripeKey)

    if (stripe.isReturnFromStripe() && this.cart.fulfilled_at) {
      this.showSuccess = true

      this.$nextTick(() => {
        this.showConfetti()
      })

      this.hasShownConfetti = true

      return
    }

    if (this.cart.fulfilled_at) {
      // Fetch the button URL/text + order
      this.submitCheckout({})
    }

    if (
      !this.isPreview &&
      this.themeOptions.general.google_analytics_enabled === true &&
      this.themeOptions.general.google_analytics_id
    ) {
      Tracking.injectGoogleAnalyticsScript(this.themeOptions.general.google_analytics_id)

      Tracking.trackGoogleAnalyticsViewItemEvent(this.cart, this.product.name)

      this.eventHub.on('discount-added', () => {
        Tracking.trackGoogleAnalyticsDiscountEvent('apply_discount', this.cart, this.product.name)
      })

      this.eventHub.on('discount-removed', () => {
        Tracking.trackGoogleAnalyticsDiscountEvent('remove_discount', this.cart, this.product.name)
      })
    }

    if (
      !this.isPreview &&
      this.themeOptions.general.meta_pixel_enabled === true &&
      this.themeOptions.general.meta_pixel_id
    ) {
      Tracking.injectMetaPixelScript(this.themeOptions.general.meta_pixel_id)

      this.eventHub.on('discount-added', () => {
        Tracking.trackMetaPixelDiscountEventAdded(this.cart)
      })

      this.eventHub.on('discount-removed', (cart) => {
        Tracking.trackMetaPixelDiscountEventRemoved(cart)
      })
    }

    Tracking.trackAddToCartEvents(this.cart, this.product.name)

    if (!this.isPreview && this.isInitialized) {
      this.trackVisit()
    }

    if (!this.isPreview) {
      this.handleCheckoutDebugState()
    }
  },

  methods: {
    setCheckoutHandler() {
      this.checkoutHandler = useCheckout(this.store.url_domain, this.cart.id, this.isApi, this.isPreview)
    },
    updateCartItem(payload) {
      if (this.isPreview) {
        return
      }

      this.checkoutHandler.updateCartItem(payload).then(() => {
        this.eventHub.emit('cart-updated')

        Tracking.trackAddToCartEvents(this.cart, this.product.name)
      })
    },
    submitCheckout(payload) {
      if (this.isPreview) {
        return
      }

      if (this.isSubmitting) {
        return
      }

      Tracking.trackPaymentInfoEvent(this.cart, this.product.name)

      this.isSubmitting = true
      this.submitForm.clearErrors()

      this.submitForm
        .transform(() => payload)
        .post(this.checkoutHandler.$route('checkout.submit'))
        .then((response) => {
          this.handleCheckoutResponse(response)
        })
        .catch((error) => {
          console.error(error)
        })
        .finally(() => {
          this.isSubmitting = false
        })
    },
    handleCheckoutResponse(response) {
      this.checkoutResponse = response.data
      this.isFulfilling = false
      this.hasFailed = false
      this.showSuccess = false

      if (this.checkoutResponse.status === 'success') {
        this.showSuccess = true
        this.buttonUrl = this.checkoutResponse.buttonUrl
        this.buttonText = this.checkoutResponse.buttonText
        this.invoiceUrl = this.checkoutResponse.invoiceUrl

        window.top.postMessage(
          {
            event: 'Checkout.Success',
            data: {
              order: JSON.parse(JSON.stringify(this.checkoutResponse.order)),
            },
          },
          '*',
        )

        Tracking.trackPurchaseEvents(this.cart, this.checkoutResponse.order, this.product.name)

        if (!this.hasShownConfetti) {
          this.$nextTick(() => {
            this.showConfetti()
          })
          this.hasShownConfetti = true
        }
      } else if (this.checkoutResponse.status === 'fulfilling') {
        this.isFulfilling = true
        this.getFulfilmentStatus()

        if (!this.hasShownConfetti) {
          this.$nextTick(() => {
            this.showConfetti()
          })
          this.hasShownConfetti = true
        }
      } else if (this.checkoutResponse.status === 'failed') {
        this.hasFailed = true
      } else if (this.checkoutResponse.status === 'review') {
        this.submitForm.errors = {
          purchase_attempt: 'review',
        }
      } else if (this.checkoutResponse.status === 'block') {
        this.submitForm.errors = {
          purchase_attempt: 'block',
        }
      } else {
        console.error('Unknown response status', this.checkoutResponse.status)
      }
    },
    getFulfilmentStatus: _.debounce(function () {
      axios
        .get(this.checkoutHandler.$route('checkout.fulfilment'))
        .then((response) => {
          this.handleCheckoutResponse(response)
        })
        .catch((error) => {
          console.error(error)
        })
    }, 1500),
    downloadContent() {
      if (this.isPreview) {
        return
      }

      top.location = this.buttonUrl
    },
    async trackVisit() {
      const fingerprint = await FingerprintJS.load({
        monitoring: false,
      })

      const cookies = useCookies(['ls_aff'])
      const affId = cookies.get('ls_aff')

      const visitorId = (await fingerprint.get()).visitorId

      axios
        .post(this.checkoutHandler.$route('checkout.track-visit'), {
          visitor_id: visitorId,
          aff: affId,
          url: location.href,
        })
        .catch(() => {})
        .finally(() => {
          window.dispatchEvent(new Event('track-visit'))
        })
    },
    goBack() {
      window.history.back()
    },
    handleCheckoutDebugState() {
      this.eventHub.on('debug-checkout-state', (state) => {
        this.paymentIsProcessing = false
        this.isFulfilling = false
        this.hasFailed = false
        this.showSuccess = false
        this.buttonUrl = null
        this.buttonText = null
        this.invoiceUrl = null
        this.submitForm.errors = {}
        this.checkoutResponse = {
          status: 'success',
          message: null,
        }

        if (state === 'processing') {
          this.paymentIsProcessing = true
        }
        if (state === 'review') {
          this.submitForm.errors = {
            purchase_attempt: 'review',
          }
        }
        if (state === 'blocked') {
          this.submitForm.errors = {
            purchase_attempt: 'block',
          }
        }
        if (state === 'failed') {
          this.hasFailed = true
          this.checkoutResponse = {
            status: 'failed',
            message: 'An example checkout error occurred',
          }
        }
        if (state === 'success') {
          this.showSuccess = true
          this.buttonUrl = 'https://example.com'
          this.buttonText = 'View Order'
          this.invoiceUrl = 'https://example.com'
        }
      })
    },
  },
}
</script>
