<template>
  <div dusk="checkout-success">
    <h2 class="mb-2 text-20 text-balance font-medium">
      <template v-if="product.confirmationTitle">{{ product.confirmationTitle }}</template>
      <template v-else-if="cart.isLeadMagnetOrOneHundredPercentDiscount()"> Whoooohoooo! </template>
      <template v-else> Thanks for your order! </template>
    </h2>
    <p class="text-balance">
      <template v-if="product.confirmationMessage">
        <div class="formatted-html" v-html="product.confirmationMessage" />
      </template>
      <template v-else-if="cart.isLeadMagnetOrOneHundredPercentDiscount()">
        Instructions are on their way to your inbox.
      </template>
      <template v-else>
        Woohoo! Your payment was successful, and your order is complete.<br />
        A receipt is on its way to your inbox.
      </template>
    </p>
    <ButtonComponent
      v-if="isFulfilling"
      dusk="checkout-success-button"
      variant="primary"
      class="mt-3 w-full max-w-35 !bg-lemon-button !text-lemon-button-text"
      :disabled="true"
    >
      <span class="mx-auto flex items-center">
        <svg
          class="mr-1 h-3 w-3 animate-spin stroke-current"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0)">
            <path
              d="M17.25 7C15.9295 5.61385 14.0656 4.75 12 4.75C7.99594 4.75 4.75 7.99594 4.75 12C4.75 16.0041 7.99594 19.25 12 19.25C14.0656 19.25 15.9295 18.3862 17.25 17"
              stroke="#141414"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </g>
          <defs>
            <clipPath id="clip0">
              <rect width="24" height="24" fill="white" />
            </clipPath>
          </defs>
        </svg>
        Processing...
      </span>
    </ButtonComponent>
    <ButtonComponent
      v-else-if="buttonUrl"
      dusk="checkout-success-button"
      variant="primary"
      class="mt-3 w-full max-w-35 !bg-lemon-button !text-lemon-button-text"
      @click="downloadContent()"
    >
      <span class="mx-auto">
        {{ buttonText }}
      </span>
    </ButtonComponent>
  </div>
</template>

<script>
import { inject } from 'vue'

import ButtonComponent from '@/Components/ButtonComponent.vue'

import useCart from './useCart.js'

export default {
  components: {
    ButtonComponent,
  },

  props: {
    isEmbed: {
      type: Boolean,
      default: false,
    },
    isFulfilling: {
      type: Boolean,
      default: false,
    },
    buttonUrl: {
      type: String,
      default: null,
    },
    buttonText: {
      type: String,
      default: null,
    },
    isPreview: {
      type: Boolean,
      default: false,
    },
    product: {
      type: Object,
      required: true,
    },
  },

  setup() {
    const cart = inject('cart')
    return {
      cart: useCart(cart),
    }
  },

  methods: {
    downloadContent() {
      if (this.isPreview) {
        return
      }

      top.location = this.buttonUrl
    },
  },
}
</script>
