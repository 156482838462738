import { computed, reactive } from 'vue'

import route from '@/Util/route.js'

import useCheckoutRequest from './useCheckoutRequest'

export default function useCheckout(storeDomain, cartId, isApi, isPreview) {
  const state = reactive({
    addDiscountWorking: false,
    removeDiscountWorking: false,
    calculateTaxWorking: false,
    updateCartItemWorking: false,
  })

  const working = computed(() => {
    return (
      state.addDiscountWorking ||
      state.removeDiscountWorking ||
      state.calculateTaxWorking ||
      state.validateWorking ||
      state.updateCartItemWorking
    )
  })

  function $route(name, params = {}) {
    if (isApi) {
      return route(
        `spa-api.${name}`,
        {
          cart: cartId,
          ...params,
        },
        undefined,
      )
    }

    return route(
      name,
      {
        domain: storeDomain,
        cart: cartId,
        ...params,
      },
      undefined,
    )
  }

  function saveEmail(email) {
    if (!email) {
      return Promise.reject('Email is required.')
    }

    return new Promise((resolve, reject) => {
      useCheckoutRequest($route('checkout.email'), {
        isFetch: true,
        body: { email: email },
        onSuccess: () => resolve(),
        onError: (errors) => reject(errors),
      })
    })
  }

  function updateCartItem(payload) {
    return new Promise((resolve, reject) => {
      state.updateCartItemWorking = true

      useCheckoutRequest($route('checkout.update'), {
        isApi,
        body: payload,
        onSuccess: () => resolve(),
        onFinish: () => (state.updateCartItemWorking = false),
        onError: () => reject(),
      })
    })
  }

  function validate(payload, paymentService) {
    return new Promise((resolve, reject) => {
      useCheckoutRequest($route('checkout.validate', { type: paymentService }), {
        isApi,
        body: payload,
        onSuccess: (data) => resolve(data),
        onError: (errors) => reject(errors),
      })
    })
  }

  function confirmEmail(email) {
    return new Promise((resolve, reject) => {
      state.confirmEmailWorking = true

      useCheckoutRequest($route('checkout.confirm-email'), {
        isApi,
        body: { email },
        onSuccess: (data) => resolve(data),
        onFinish: () => (state.confirmEmailWorking = false),
        onError: (errors) => reject(errors),
      })
    })
  }

  function addDiscount(discountCode, service) {
    return new Promise((resolve, reject) => {
      if (isPreview) {
        return reject('Adding a discount is not available in preview mode.')
      }

      if (!discountCode) {
        return reject('Discount code is required.')
      }

      state.addDiscountWorking = true

      useCheckoutRequest($route('checkout.add-discount', { type: service }), {
        isApi,
        body: { discount_code: discountCode },
        onSuccess: (data) => {
          resolve(data)
        },
        onFinish: () => (state.addDiscountWorking = false),
        onError: (errors) => reject(errors),
      })
    })
  }

  function removeDiscount() {
    return new Promise((resolve, reject) => {
      if (isPreview) {
        return reject('Removing a discount is not available in preview mode.')
      }

      state.removeDiscountWorking = true

      useCheckoutRequest($route('checkout.remove-discount'), {
        isApi,
        method: 'DELETE',
        onSuccess: (data) => {
          resolve(data)
        },
        onFinish: () => (state.removeDiscountWorking = false),
        onError: (errors) => reject(errors),
      })
    })
  }

  function calculateTax(formData) {
    return new Promise((resolve, reject) => {
      if (isPreview) {
        return reject('Calculating tax is not available in preview mode.')
      }

      if (!formData.country || !formData.postal_code) {
        return reject('Country and postal code are required.')
      }

      if (
        formData.country === 'US' &&
        (!formData.line1 || !formData.city || !formData.state || !formData.postal_code)
      ) {
        return reject('Ensure address line 1, line2, city, and state are filled.')
      }

      state.calculateTaxWorking = true

      const bodyParams = {
        country: formData.country,
        postal_code: formData.postal_code,
        tax_number: formData.tax_number,
      }

      if (formData.country === 'US') {
        bodyParams.line1 = formData.line1
        bodyParams.line2 = formData.line2
        bodyParams.city = formData.city
        bodyParams.state = formData.state
      }

      useCheckoutRequest($route('checkout.tax'), {
        isApi,
        body: bodyParams,
        onSuccess: (data) => {
          resolve(data)
        },
        onFinish: () => (state.calculateTaxWorking = false),
        onError: (errors) => reject(errors),
      })
    })
  }

  function requestTaxExemptLink() {
    return new Promise((resolve, reject) => {
      useCheckoutRequest($route('checkout.tax-exempt.redirect'), {
        isApi,
        method: 'POST',
        onSuccess: (data) => {
          resolve(data)
        },
        onError: (errors) => reject(errors),
      })
    })
  }

  return {
    working,
    $route,
    saveEmail,
    addDiscount,
    removeDiscount,
    calculateTax,
    updateCartItem,
    validate,
    requestTaxExemptLink,
  }
}
