<template>
  <div class="relative">
    <input
      v-bind="$attrs"
      ref="input"
      :type="type"
      :class="['form-input', inputClass]"
      :value="modelValue"
      @input="$emit('update:modelValue', $event.target.value)"
    />
    <button
      v-if="!hideWhenEmpty || (hideWhenEmpty && modelValue)"
      type="button"
      class="absolute right-[6px] top-[6px] z-20 rounded-xs bg-wedges-gray-50 px-1 py-[2px] text-13 font-medium text-wedges-gray-900 focus:outline-none"
      @click="$emit('apply')"
    >
      {{ buttonLabel }}
    </button>
  </div>
</template>

<script>
export default {
  inheritAttrs: false,

  props: {
    modelValue: {
      type: [String, Number],
      required: true,
    },
    buttonLabel: {
      type: String,
      default: 'Submit',
    },
    type: {
      type: String,
      default: 'text',
    },
    inputClass: {
      type: String,
      default: '',
    },
    hideWhenEmpty: {
      type: Boolean,
      default: false,
    },
  },

  emits: [
    'update:modelValue',
    'apply'
  ],
}
</script>
