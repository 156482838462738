<template>
  <TransitionRoot :show="isOpen" as="template">
    <Dialog :initial-focus="closeButtonRef" class="relative z-150" @close="emit('close')">
      <TransitionChild
        enter="transition duration-100 ease-out"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="transition duration-200 ease-in"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-white/80" aria-hidden="true" />
      </TransitionChild>
      <div class="fixed inset-0 w-full h-screen flex items-center justify-center">
        <TransitionChild
          enter="transition duration-100 ease-out"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="transition duration-75 ease-in"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <DialogPanel class="flex w-100 rounded bg-white shadow-modal">
            <div class="w-1/2 rounded-l bg-wedges-purple-600">
              <div class="relative w-full h-full overflow-hidden rounded-l">
                <CheckoutModalBg class="absolute inset-0 w-full h-full object-cover" />
              </div>
            </div>
            <div class="w-1/2">
              <div class="px-6 py-5 space-y-2">
                <h2 class="text-24 font-medium tracking-tight">Secure checkout powered by Lemon Squeezy</h2>
                <p class="text-wedges-gray">
                  Lemon Squeezy is a global Merchant of Record service provider to thousands of software companies
                  worldwide. Lemon Squeezy offers secure checkouts, payment processing, global tax compliance and other
                  services to make compliance easy.
                </p>
                <ul>
                  <li class="flex items-center py-0.5">
                    <svg
                      class="w-3 h-3 mr-1.5"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect width="24" height="24" rx="12" fill="#EEFBF4" />
                      <path
                        d="M15.25 9.75L10.7812 14.25L8.75 11.75"
                        stroke="#1E874C"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    <p>Your details are secure and encrypted</p>
                  </li>
                  <li class="flex items-center py-0.5">
                    <svg
                      class="w-3 h-3 mr-1.5"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect width="24" height="24" rx="12" fill="#EEFBF4" />
                      <path
                        d="M15.25 9.75L10.7812 14.25L8.75 11.75"
                        stroke="#1E874C"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    <p>Backed by Stripe payments</p>
                  </li>
                  <li class="flex items-center py-0.5">
                    <svg
                      class="w-3 h-3 mr-1.5"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect width="24" height="24" rx="12" fill="#EEFBF4" />
                      <path
                        d="M15.25 9.75L10.7812 14.25L8.75 11.75"
                        stroke="#1E874C"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    <p>Multiple ways to pay</p>
                  </li>
                </ul>
                <p class="text-wedges-gray">
                  By providing {{ props.store.name }} the above Merchant of Record services, you will see
                  <span class="text-wedges-gray-900 font-medium">{{ props.store.descriptor }}</span>
                  appear on your bank or card statement for this order.
                </p>
                <p class="text-wedges-gray">
                  If you have any questions relating to this order,
                  <a
                    href="https://www.lemonsqueezy.com/help"
                    target="_blank"
                    class="underline hover:text-wedges-gray-900"
                    >visit Lemon Squeezy support</a
                  >
                </p>
                <div class="pt-2">
                  <button ref="closeButtonRef" class="btn btn-center w-full" @click="emit('close')">
                    Close window
                  </button>
                </div>
              </div>
            </div>
          </DialogPanel>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup>
import { Dialog, DialogPanel, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { ref } from 'vue'

import CheckoutModalBg from '../../../img/checkout-modal-bg.svg'

const props = defineProps({
  store: {
    type: Object,
    required: true,
  },
  isOpen: {
    type: Boolean,
    default: false,
  },
})

const emit = defineEmits(['close'])
const closeButtonRef = ref(null)
</script>
