<template>
  <SelectComponent
    v-slot="state"
    :options="stateOptions"
    :model-value="modelValue"
    :required="required"
    placeholder="Select a state..."
    @update:model-value="(newVal) => $emit('update:modelValue', newVal)"
  >
    <span class="truncate">
      {{ state.label }}
    </span>
  </SelectComponent>
</template>

<script>
import SelectComponent from '@/Components/Forms/SelectComponent.vue'
import Countries from '@/Mixins/Countries.js'

export default {
  components: {
    SelectComponent,
  },
  mixins: [Countries],
  props: {
    modelValue: {
      type: String,
      default: '',
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:modelValue'],
  computed: {
    stateOptions() {
      return this.usStates().map((state) => ({
        key: state.code,
        label: state.name,
      }))
    },
  },
}
</script>
