<template>
  <div class="relative">
    <div
      v-if="modelValue && valid !== null"
      class="pointer-events-none absolute right-0 top-0 z-20 px-2 py-1"
      :class="{
        'text-wtf-emerald': valid === true,
        'text-wtf-paradise': valid !== true,
      }"
    >
      <SvgVue v-if="valid" key="valid" icon="Selected" class="h-3 w-3 fill-current" />
      <SvgVue v-else key="invalid" icon="Invalid" class="h-3 w-3 fill-current" />
    </div>
    <input
      v-bind="$attrs"
      type="text"
      class="form-input"
      :value="modelValue"
      @input="$emit('update:modelValue', $event.target.value)"
    />
  </div>
</template>

<script>
import SvgVue from '@/Components/SvgVue.vue'

export default {
  components: {
    SvgVue,
  },
  inheritAttrs: false,

  props: {
    modelValue: {
      type: [String, Number],
      default: '',
    },
    valid: {
      type: Boolean,
      default: null,
    },
  },

  emits: ['update:modelValue'],
}
</script>
