<template>
  <div
    :style="{
      background: splitBackground
        ? 'linear-gradient(to right, var(--lemon-background, #F7F7F8) 50%, #FFFFFF 50%)'
        : 'var(--lemon-background, #F7F7F8)',
    }"
    class="min-h-full"
  >
    <GlobalAlert v-if="alert" :variant="alert.variant" :show="showAlert">
      {{ alert.message }}
    </GlobalAlert>
    <ImpersonationNotice v-if="isImpersonating" />
    <TestModeNotice v-if="showTestModeNotice && !isPreview">
      <slot name="testMode" />
    </TestModeNotice>
    <slot name="header" />
    <div class="mx-auto max-w-200">
      <slot />
    </div>
  </div>
</template>
<script>
import GlobalAlert from '@/Components/GlobalAlert.vue'
import ImpersonationNotice from '@/Layouts/Components/ImpersonationNotice.vue'
import TestModeNotice from '@/Layouts/Components/TestModeNotice.vue'
import GlobalAlertMixin from '@/Mixins/GlobalAlert.js'

export default {
  components: {
    GlobalAlert,
    ImpersonationNotice,
    TestModeNotice,
  },
  mixins: [GlobalAlertMixin],

  props: {
    showTestModeNotice: {
      type: Boolean,
      default: false,
    },
    isPreview: {
      type: Boolean,
      default: false,
    },
    splitBackground: {
      type: Boolean,
      default: true,
    },
  },

  computed: {
    isImpersonating() {
      return this.$page?.props.isImpersonating
    },
  },
}
</script>
