<template>
  <div class="sticky top-0 z-150 flex items-center justify-between bg-wtf-mango px-3 py-0.5 text-12 shadow-sm">
    <div>
      Impersonating
      <span class="font-semibold"> {{ currentUser.name }} ({{ currentUser.email }}) </span>
    </div>
    <div>
      <a :href="$route('stop-impersonating')" class="ml-1 font-semibold text-wtf-majorelle hover:underline">
        Stop impersonating
      </a>
    </div>
  </div>
</template>
