<template>
  <div v-if="svg" class="svg-avatar" v-html="svg" />
</template>

<script>
import UIAvatarSvg from 'ui-avatar-svg'

export default {
  props: {
    text: {
      type: String,
      required: true,
    },
    bgColor: {
      type: String,
      default: '#ff0000',
    },
    textColor: {
      type: String,
      default: '#ffffff',
    },
  },

  data() {
    return {
      svg: null,
    }
  },

  mounted() {
    this.svg = new UIAvatarSvg()
      .text(this.text ? this.text.charAt(0).toUpperCase() : '')
      .bgColor(this.bgColor)
      .textColor(this.textColor)
      .fontSize(0.6)
      .fontWeight(600)
      .fontFamily(
        'system-ui, -apple-system, \'Segoe UI\', Roboto, Helvetica, Arial, sans-serif, \'Apple Color Emoji\', \'Segoe UI Emoji\'',
      )
      .generate()
  },
}
</script>
