<template>
  <div dusk="checkout-failed">
    <h2 class="mb-2 text-20 font-medium">This is embarrassing 😬</h2>
    <p v-if="checkoutResponse.message" class="mb-2">
      Something went wrong trying to fulfill your order:<br />
      <span class="text-wedges-red">{{ checkoutResponse.message }}</span>
    </p>
    <p>
      If you continue to have issues, please
      <a href="#" class="text-wtf-majorelle hover:underline" @click.prevent="tryAgain()">try again</a> or contact
      support at
      <a href="https://www.lemonsqueezy.com/help" class="text-wtf-majorelle hover:underline"
        >https://www.lemonsqueezy.com/help</a
      >
      and reference&nbsp;this&nbsp;cart&nbsp;ID:
    </p>
    <p>
      <code>{{ cart.id }}</code>
    </p>
  </div>
</template>

<script>
export default {
  props: {
    cart: {
      type: Object,
      required: true,
    },
    checkoutResponse: {
      type: Object,
      default: () => ({}),
    },
  },

  methods: {
    tryAgain() {
      location.reload()
    },
  },
}
</script>
