<template>
  <div v-if="product.media.length" ref="carousel" class="glide group relative rounded-t">
    <div class="glide__track overflow-hidden rounded-t" :class="{ 'rounded-b': !isEmbed }" data-glide-el="track">
      <ul class="glide__slides">
        <li v-for="(url, index) in product.media" :key="index" class="glide__slide">
          <div
            class="product-media-item aspect-h-3 aspect-w-4 w-full bg-cover bg-center bg-no-repeat"
            :style="{ backgroundImage: `url(${url})` }"
          />
        </li>
      </ul>
    </div>
    <div v-if="product.media.length > 1" class="glide__arrows hidden group-hover:block" data-glide-el="controls">
      <button
        class="glide__arrow glide__arrow--left absolute left-0 top-0 flex h-full -translate-x-2.5 transform items-center focus:outline-none"
        data-glide-dir="<"
      >
        <div class="flex h-5 w-5 items-center rounded-full bg-light-97">
          <SvgVue icon="Arrow Left" class="mx-auto h-3 w-3 fill-transparent stroke-current" />
        </div>
      </button>
      <button
        class="glide__arrow glide__arrow--right absolute right-0 top-0 flex h-full translate-x-2.5 transform items-center focus:outline-none"
        data-glide-dir=">"
      >
        <div class="flex h-5 w-5 items-center rounded-full bg-light-97">
          <SvgVue icon="Arrow Right" class="mx-auto h-3 w-3 fill-transparent stroke-current" />
        </div>
      </button>
    </div>
    <div
      v-if="product.media.length > 1 && !isEmbed"
      class="glide__bullets absolute bottom-0 left-0 flex w-full justify-center pb-3"
      data-glide-el="controls[nav]"
    >
      <button
        v-for="n in product.media.length"
        :key="n"
        class="glide__bullet mx-0.5 h-1 w-1 rounded-full bg-white opacity-40 focus:outline-none"
        :data-glide-dir="`=${n - 1}`"
      />
    </div>
  </div>
</template>

<script>
import Glide from '@glidejs/glide'
import imagesLoaded from 'imagesloaded'

import SvgVue from '@/Components/SvgVue.vue'

export default {
  components: {
    SvgVue,
  },
  props: {
    product: {
      type: Object,
      required: true,
    },
    isEmbed: {
      type: Boolean,
      default: false,
    },
    isPreview: {
      type: Boolean,
      default: false,
    },
  },

  inject: ['isApi'],

  data() {
    return {
      carousel: null,
    }
  },

  watch: {
    product() {
      this.destroyCarousel()
      this.createCarousel()
    },
    isEmbed() {
      this.destroyCarousel()
      this.createCarousel()
    },
  },

  mounted() {
    this.createCarousel()

    imagesLoaded(this.$refs.carousel, { background: '.product-media-item' }, () => {
      this.updateCarousel()
    })
  },

  beforeUnmount() {
    this.destroyCarousel()
  },

  methods: {
    createCarousel() {
      if (!this.isEmbed && this.isPreview) {
        return
      }

      if (this.product.media.length > 1) {
        this.$nextTick(() => {
          this.carousel = new Glide('.glide', {
            type: 'carousel',
            gap: 0,
          }).mount()
        })
      }
    },
    updateCarousel() {
      if (!this.carousel) {
        return
      }

      this.$nextTick(() => {
        try {
          this.carousel.update()
        } catch (error) {}
      })
    },
    destroyCarousel() {
      if (!this.carousel) {
        return
      }

      this.carousel.destroy()
    },
  },
}
</script>
