import { Inertia } from '@inertiajs/inertia'
import { useApiFetch } from '~/composables/useApiFetch'
import { useCheckoutState } from './useCheckoutState'
import axios from 'axios'

const { setState: updateCheckout, isReady } = useCheckoutState()

function inertiaRequest(url, options) {
  const method = options.method.toLowerCase()

  const params = {
    preserveScroll: true,
    preserveState: options.preserveState,
    onSuccess: (page) => {
      options.onSuccess(page)
    },
    onError: (errors) => {
      options.onError(errors)
    },
    onFinish: () => {
      options.onFinish()
    },
  }

  if (method === 'delete') {
    Inertia.delete(url, params)
  } else {
    Inertia[method](url, options.body, params)
  }
}

function apiRequest(url, options) {
  // Avoid making requests until the checkout is initialized
  if (!isReady) {
    return
  }

  return useApiFetch(url, {
    method: options.method,
    body: options.body,
    onResponse({ response }) {
      options.onFinish()

      if (response.ok) {
        if (options.update) {
          updateCheckout(response._data)
        }

        if (response._data.errors) {
          options.onError(response._data.errors)
        } else {
          options.onSuccess(response._data)
        }
      } else {
        const errors = Object.fromEntries(Object.entries(response._data.errors).map(([key, value]) => [key, value[0]]))

        options.onError(errors)
      }
    },
  })
}

function fetchRequest(url, options) {
  return axios
    .post(url, options.body)
    .then((response) => {
      options.onSuccess(response.data)
    })
    .catch((error) => {
      if (!error.response) {
        return
      }

      const errors = Object.fromEntries(
        Object.entries(error.response.data.errors).map(([key, value]) => [key, value[0]]),
      )

      options.onError(errors)
    })
    .finally(() => {
      options.onFinish()
    })
}

export default function useCheckoutRequest(url, options) {
  const params = {
    isApi: false,
    isFetch: false,
    body: {},
    method: 'POST',
    // update checkout state
    update: true,
    // preserve Inertia state
    preserveState: true,
    onFinish: () => {},
    onSuccess: () => {},
    onError: () => {},
    // any extra `fetch` options
    ...options,
  }

  if (params.isApi) {
    return apiRequest(url, params)
  } else if (params.isFetch) {
    return fetchRequest(url, params)
  } else {
    return inertiaRequest(url, params)
  }
}
