<template>
  <div
    class="fixed bottom-0 left-0 z-150 w-full mt-3 flex items-center justify-center bg-wedges-yellow-400 text-wedges-gray-800 px-2 py-1 space-x-2"
  >
    <div>
      Style:
      <Link
        v-if="cart"
        :href="checkoutHandler.$route('checkout.toggle-embed')"
        class="mr-1 text-wedges-purple hover:underline"
      >
        Toggle Embed
      </Link>
    </div>
    <div class="flex items-center">
      <label class="mr-1">State:</label>
      <select class="form-input form-select" @change="changeState($event.target.value)">
        <option v-for="state in checkoutStates" :key="state.key" :value="state.key">
          {{ state.label }}
        </option>
      </select>
    </div>
    <div v-if="cart.affiliate_click_id">Affiliate click: {{ cart.affiliate_click_id }}</div>
  </div>
</template>

<script>
import { Link } from '@inertiajs/inertia-vue3'

import useCheckout from './useCheckout.js'

export default {
  components: {
    Link,
  },

  inject: ['isApi', 'isPreview'],

  props: {
    cart: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      checkoutHandler: null,
    }
  },

  computed: {
    checkoutStates() {
      return [
        { key: 'normal', label: 'normal' },
        { key: 'processing', label: 'processing' },
        { key: 'review', label: 'review' },
        { key: 'blocked', label: 'blocked' },
        { key: 'failed', label: 'failed' },
        { key: 'success', label: 'success' },
      ]
    },
  },

  created() {
    this.checkoutHandler = useCheckout(this.cart.store.url_domain, this.cart.id, this.isApi, this.isPreview)
  },

  methods: {
    changeState(state) {
      this.eventHub.emit('debug-checkout-state', state)
    },
  },
}
</script>
