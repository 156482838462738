<template>
  <div>
    <h2 class="mb-2 text-20 font-medium">Whoa there!</h2>
    <p>
      This transaction has been blocked and you have not been charged. If you believe this to be an error please contact
      support at
      <a href="https://www.lemonsqueezy.com/help" class="text-wtf-majorelle hover:underline"
        >https://www.lemonsqueezy.com/help</a
      >.
    </p>
  </div>
</template>
