export default {
  data() {
    return {
      clientAlert: null,
      showAlert: false,
      alertTimeoutId: null,
    }
  },

  computed: {
    alert() {
      return this.$page?.props.alert || this.clientAlert
    },
  },

  mounted() {
    this.eventHub.on('create-alert', (data) => {
      this.clientAlert = data
    })

    if (this.alert) {
      this.showGlobalAlert()
    }
  },

  methods: {
    showGlobalAlert() {
      this.showAlert = true
      clearTimeout(this.alertTimeoutId)
      this.alertTimeoutId = setTimeout(() => {
        ;(this.showAlert = false), (this.clientAlert = null)
      }, 3000)
    },
  },

  watch: {
    alert(newVal) {
      if (newVal) {
        this.showGlobalAlert()
      }
    },
  },
}
