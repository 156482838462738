<template>
  <div class="button-toggle flex w-full items-center rounded bg-light-97 p-0.25 dark:bg-dark-19">
    <label
      v-for="option in options"
      :key="option.key"
      class="relative cursor-pointer rounded border px-2 py-[5px] text-center"
      :class="{
        'border-transparent text-grey hover:text-body dark:text-dark-grey dark:hover:text-dark-95':
          modelValue !== option.key,
        'active border-wedges-gray-200 bg-white font-medium text-body shadow-button dark:border-dark-28 dark:bg-dark-16 dark:text-dark-95':
          modelValue === option.key,
        'opacity-50': disabled,
      }"
      :style="{ width: buttonWidth }"
      @click="handleClick(option)"
    >
      {{ option.label }}
    </label>
  </div>
</template>

<script>
export default {
  props: {
    modelValue: {
      type: [String, Number],
      required: true,
    },
    options: {
      type: Array,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:modelValue'],

  computed: {
    buttonWidth() {
      return 100 / this.options.length + '%'
    },
  },

  methods: {
    handleClick(option) {
      if (this.disabled) {
        return
      }

      this.$emit('update:modelValue', option.key)
    },
  },
}
</script>

<style>
.button-toggle {
  label:not(:last-child):not(.active):after {
    content: '|';
    position: absolute;
    right: -4px;
    top: 6px;
    color: #d1d1db;
  }
}
</style>
