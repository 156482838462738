<script setup>
import { Loader } from '@googlemaps/js-api-loader'
import { onMounted, ref } from 'vue'

defineProps({
  city: {
    type: String,
    required: false,
  },
  zip: {
    type: String,
    required: false,
  },
  state: {
    type: String,
    required: false,
  },
  disabled: {
    type: Boolean,
    required: false,
  },
})

const emit = defineEmits(['update:line1', 'update:city', 'update:zip', 'update:state'])

const input = ref()
// eslint-disable-next-line
const address = defineModel()

const loader = new Loader({
  apiKey: import.meta.env.VITE_GOOGLE_MAPS_API_KEY,
  version: 'weekly',
})

if (typeof window !== 'undefined') {
  window.gm_authFailure = () => {
    input.value.disabled = false
    input.value.focus()
    input.value.placeholder = 'Address line 1'

    return false
  }
}

async function initializeGooglePlaces() {
  const Places = await loader.importLibrary('places')

  const autocomplete = new Places.Autocomplete(input.value, {
    componentRestrictions: { country: 'us' },
    fields: ['address_components'],
    language: 'en',
  })

  autocomplete.addListener('place_changed', () => {
    const place = autocomplete.getPlace()

    if (!place.address_components) {
      return
    }

    const line1 = place.address_components
      .filter((component) => component.types.includes('street_number') || component.types.includes('route'))
      .map((component) => component.long_name)
      .join(' ')

    const zip = place.address_components.find((component) => component.types.includes('postal_code'))?.long_name ?? ''
    const city = place.address_components.find((component) => component.types.includes('locality'))?.long_name ?? ''
    const state =
      place.address_components.find((component) => component.types.includes('administrative_area_level_1'))
        ?.short_name ?? ''

    address.value = line1

    emit('update:city', city)
    emit('update:state', state)
    emit('update:zip', zip)
  })
}

onMounted(async () => {
  if (import.meta.env.VITE_GOOGLE_MAPS_ENABLED == 'true') {
    initializeGooglePlaces()
  }
})
</script>

<template>
  <input
    ref="input"
    v-model="address"
    :disabled="disabled"
    type="text"
    placeholder="Address line 1"
    class="w-64 form-input border-b rounded-none"
  />
</template>
