import { computed } from 'vue'

const CSS_VARIABLE_PREFIX = '--lemon-'

export default function useCheckoutStyles(checkoutOptions, themeOptions) {
  // Determine the allowed CSS variables based on the embed option
  const allowedCSSVars = checkoutOptions.embed
    ? ['background', 'terms-privacy', 'links', 'button', 'button-text']
    : [
        'active',
        'background',
        'borders',
        'button-text',
        'button',
        'checkbox',
        'headings',
        'links',
        'primary-text',
        'secondary-text',
      ]

  function _resolveStyleValue(checkoutOptionKey, themeOptionKey) {
    const options = checkoutOptions.embed ? themeOptions.overlay : themeOptions.checkout
    const override = checkoutOptions.embed ? themeOptions.overlay.override : themeOptions.checkout.override

    // Check if colors are customized through the checkout options
    if (checkoutOptions[checkoutOptionKey]) return checkoutOptions[checkoutOptionKey]

    // If override is enabled, return the theme option value
    if (override) return options[themeOptionKey]

    // Return null if no condition is met, use the default theme colors
    return null
  }

  return computed(() => {
    const styleVars = {
      background: _resolveStyleValue('background_color', 'background'),
      headings: _resolveStyleValue('headings_color', 'headings'),
      'primary-text': _resolveStyleValue('primary_text_color', 'primaryText'),
      'secondary-text': _resolveStyleValue('secondary_text_color', 'secondaryText'),
      'terms-privacy': _resolveStyleValue('terms_privacy_color', 'termsPrivacy'),
      links: _resolveStyleValue('links_color', 'links'),
      borders: _resolveStyleValue('borders_color', 'borders'),
      checkbox: _resolveStyleValue('checkbox_color', 'checkbox'),
      active: _resolveStyleValue('active_state_color', 'activeState'),
      button: _resolveStyleValue('button_color', 'button') ?? themeOptions.general['button_color'],
      'button-text': _resolveStyleValue('button_text_color', 'buttonText') ?? themeOptions.general['button_text_color'],
    }

    return Object.keys(styleVars).reduce((styles, key) => {
      if (styleVars[key] && allowedCSSVars.includes(key)) {
        styles[`${CSS_VARIABLE_PREFIX}${key}`] = styleVars[key]
      }
      return styles
    }, {})
  })
}
